import React from 'react'
import PropTypes from 'prop-types'

const MethodDescriptionCard = (props) => {
  return (
    <div className="method-description-card">
      <div className="method-description-card__img">{props.svg}</div>
      <span className="method-description-card__title">{props.title}</span>
      <p className="method-description-card__description">{props.description}</p>
    </div>
  )
}

MethodDescriptionCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  svg: PropTypes.element
}

export default MethodDescriptionCard
