import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'

const ApplicationForm = () => {
  const { t } = useTranslation()

  const [validated, setValidated] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [validForm, setValidForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorWhenSubmiting, setErrorWhenSubmiting] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [file, setFile] = useState()

  const handleSubmit = (event) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()

    setValidForm(form.checkValidity())
    if (form.checkValidity()) {
      const formData = new FormData()
      formData.append('name', name)
      formData.append('file', file)
      formData.append('linkedin', linkedin)
      formData.append('number', number)
      formData.append('email', email)
      var xhr = new XMLHttpRequest()
      xhr.open('POST', 'https://hooks.zapier.com/hooks/catch/3206838/ox7ce1j/')
      xhr.send(formData)
      setLoading(true)
      xhr.addEventListener('loadend', () => {
        setLoading(false)
        setSubmitted(true)
      })
      xhr.addEventListener('error', () => {
        setLoading(false)
        setErrorWhenSubmiting(true)
      })
    }
    setValidated(true)
  }

  const handleFileUpload = (event) => {
    setFile(event.target.files[0])
  }

  const form = (
    <div>
      <h1 className="form-title">{t('applicationForm_title')}</h1>
      <span className="mandatory-label">{t('applicationForm_mandatory')}</span>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>
            <span className="mandatory">* </span>
            {t('applicationForm_name')}
          </Form.Label>
          <Form.Control required value={name} onChange={(e) => setName(e.target.value)} />
          <Form.Control.Feedback type="invalid" className="mandatory">
            {t('applicationForm_nameMandatory')}
          </Form.Control.Feedback>
          <Form.Control.Feedback>Ok! 👍</Form.Control.Feedback>
        </Form.Group>
        <div className="form-grid">
          <Form.Group controlId="formEmail">
            <Form.Label>
              <span className="mandatory">* </span>
              {t('applicationForm_email')}
            </Form.Label>
            <Form.Control required type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <Form.Control.Feedback type="invalid" className="mandatory">
              {t('applicationForm_emailMandatory')}
            </Form.Control.Feedback>
            <Form.Control.Feedback>Ok! 👍</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label>
              <span className="mandatory">* </span>
              {t('applicationForm_phoneNumber')}
            </Form.Label>
            <Form.Control required type="tel" value={number} onChange={(e) => setNumber(e.target.value)} />
            <Form.Control.Feedback type="invalid" className="mandatory">
              {t('applicationForm_phoneNumberMandatory')}
            </Form.Control.Feedback>
            <Form.Control.Feedback>Ok! 👍</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formLinkedInProfile">
            <Form.Label>LinkedIn</Form.Label>
            <Form.Control value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
            <Form.Text></Form.Text>
          </Form.Group>
          <Form.Group controlId="formUploadCV">
            <Form.Label>{t('applicationForm_cv')}</Form.Label>
            <Form.Label className="upload-trigger">{file ? file.name : cloud}</Form.Label>
            <Form.File
              className="hide"
              onChange={handleFileUpload}
              accept="application/pdf,application/msword,
application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
            <Form.Text></Form.Text>
          </Form.Group>
        </div>
        <div className="center">
          <Button type="submit" disabled={loading}>
            <Spinner className={loading ? '' : 'hide'} animation="border" variant="light" />
            {t('applicationForm_submit')}
          </Button>
          <span className="tablet-hide desktop-hide">
            <span className="mandatory  ">{validated && !validForm && t('applicationForm_formRequirements')}</span>
          </span>
          <span className="mandatory">{errorWhenSubmiting && t('applicationForm_errorWhenSubmiting')}</span>
        </div>
      </Form>
    </div>
  )

  const formSuccess = (
    <div className="form-success">
      <span id="raised-hands-emoji">🙌</span>
      <h1>{t('applicationForm_formSuccess')}</h1>
      <Link to="/">
        <Button>BACK</Button>
      </Link>
    </div>
  )

  return (
    <section id="application-form">
      <div className="wrapper full-rounded-section bg-white">
        {!submitted && form}
        {submitted && formSuccess}
      </div>
    </section>
  )
}

const cloud = (
  <svg xmlns="http://www.w3.org/2000/svg" width="52.161" height="34.519" viewBox="0 0 52.161 34.519">
    <g id="Orion_download-cloud" transform="translate(-1.398 -11.227)">
      <path
        id="layer2"
        d="M39.271,42.458h2.538a11,11,0,1,0,0-22h-.423a14.384,14.384,0,0,0-27.329,3.723,9.307,9.307,0,1,0-2.708,18.276H15.58"
        transform="translate(0 0)"
        fill="none"
        stroke="#848484"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        id="layer1"
        d="M6.769,0V18.614M0,11.845l6.769,6.769,6.769-6.769"
        transform="translate(34.194 44.996) rotate(180)"
        fill="none"
        stroke="#848484"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default ApplicationForm
