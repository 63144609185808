import React from 'react'

import Footer from './components/Footer'
import Header from './components/Header'

import LandingContent from './components/LandingContent'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ApplicationForm from './pages/ApplicationForm'

import CookieBanner from './components/CookieBanner'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <LandingContent />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/application-form">
            <ApplicationForm />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <Footer />
        <CookieBanner />
      </Router>
    </div>
  )
}

export default App
