import React from 'react'

export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 84 84">
    <g id="Group_1353" data-name="Group 1353" transform="translate(-679 -1303)">
      <path
        id="layer2"
        d="M42,2V82M82,42H2"
        transform="translate(679 1303)"
        fill="none"
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <g id="Group_1351" data-name="Group 1351">
        <circle
          id="layer1"
          cx="32"
          cy="32"
          r="32"
          transform="translate(689 1313)"
          fill="none"
          stroke="#0865de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <circle
          id="layer1-2"
          data-name="layer1"
          cx="14.667"
          cy="14.667"
          r="14.667"
          transform="translate(706.333 1330.333)"
          fill="none"
          stroke="#0865de"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
      </g>
    </g>
  </svg>
)
