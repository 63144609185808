import React from 'react'

const CrossIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="14.828" viewBox="0 0 14.828 14.828">
    <g id="Group_1373" data-name="Group 1373" transform="translate(-997.086 -649.086)">
      <line
        id="Line_241"
        data-name="Line 241"
        x2="12"
        y2="12"
        transform="translate(998.5 650.5)"
        fill="none"
        stroke="#262626"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Line_242"
        data-name="Line 242"
        x2="12"
        y2="12"
        transform="translate(1010.5 650.5) rotate(90)"
        fill="none"
        stroke="#262626"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default CrossIcon
