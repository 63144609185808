import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer>
      <div className="wrapper">
        <span>
          {t('footer_spinoff')}
          <strong>
            {' '}
            <a href="https://www.theagilemonkeys.com/">{t('footer_agile')}</a>
          </strong>
          <br className="desktop-hide" />
          <br className="desktop-hide" />
          <Link className="desktop-hide mobile-hide" to="/privacy-policy">
            {t('footer_privacyPolicy')}
          </Link>
        </span>
        <span>
          <Link className="tablet-hide" to="/privacy-policy">
            {t('footer_privacyPolicy')}
          </Link>
        </span>
        <span id="copyright">{t('footer_copyright')}</span>
      </div>
    </footer>
  )
}

export default Footer
