import React from 'react'
import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const JoinEnglish4Execs = () => {
  const { t } = useTranslation()

  const [formSubmitted, setFormSubmitted] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    var xhr = new XMLHttpRequest()
    xhr.open('POST', 'https://hooks.zapier.com/hooks/catch/3206838/oxk7eoh/')
    xhr.send(JSON.stringify({ name, email, message }))
    xhr.addEventListener('load', () => setFormSubmitted(true))
  }

  return (
    <section className="join-english4execs">
      <div className="rounded-section bg-light-gray">
        <div className="wrapper">
          <span className="join-english4execs__title">
            {t('join_english4exec_title')}
            <span className="e4e-logo">
              English4<span>Exec</span>
            </span>
            ?
          </span>
          <Link to="/application-form">
            <button className="btn">{t('join_english4exec_button')}</button>
          </Link>
        </div>
        <div className="wrapper full-rounded-section bg-white">
          <span className="join-english4execs__title">🤔</span>
          <h2>{t('contactus_description')}</h2>
          <h3>{t('contactus_title')}</h3>
          <form className="contact-us-form" onSubmit={handleSubmit}>
            <label htmlFor="name">{t('contactus_name')} </label>
            <input
              id="input__name"
              name="name"
              type="text"
              maxLength="255"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="email">{t('contactus_email')} </label>
            <input
              id="input__email"
              name="email"
              type="text"
              maxLength="255"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="element_3">{t('contactus_message')} </label>
            <div>
              <textarea
                id="input__message"
                name="message"
                rows={8}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="buttons-container">
              <button className={`${formSubmitted === true ? 'hide' : 'btn'}`} type="submit">
                {t('contactus_button')}
              </button>
              <button className={`${formSubmitted === true ? 'btn success' : 'hide'}`}>
                {t('contactus_received')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default JoinEnglish4Execs
