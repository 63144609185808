import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <div className={'wrapper'}>
      <div className={'privacy-policy'}>
        <h1>{t('privacyPolicy_mainTitle')}</h1>
        <h2>{t('privacyPolicy_subTitle')}</h2>

        <h3>{t('privacyPolicy_dataWeCollect.title')}</h3>
        <ul className="dashed">
          <li>{t('privacyPolicy_dataWeCollect.point1')}</li>
          <li>{t('privacyPolicy_dataWeCollect.point2')}</li>
        </ul>

        <h3>{t('privacyPolicy_howWeCollectData.title')}</h3>
        <p>{t('privacyPolicy_howWeCollectData.paragraph1')}</p>
        <ul className="dashed">
          <li>{t('privacyPolicy_howWeCollectData.point1')}</li>
          <li>{t('privacyPolicy_howWeCollectData.point2')}</li>
          <li>{t('privacyPolicy_howWeCollectData.point3')}</li>
        </ul>
        <p>{t('privacyPolicy_howWeCollectData.paragraph2')}</p>

        <h3>{t('privacyPolicy_howWeUseData.title')}</h3>
        <p>{t('privacyPolicy_howWeUseData.paragraph1')}</p>

        <h3>{t('privacyPolicy_howWeStoreData.title')}</h3>
        <p>{t('privacyPolicy_howWeStoreData.paragraph1')}</p>

        <h3>{t('privacyPolicy_dataProtectionRights.title')}</h3>
        <p>{t('privacyPolicy_dataProtectionRights.paragraph1')}</p>
        <ul className="dashed">
          <li>{t('privacyPolicy_dataProtectionRights.point1')}</li>
          <li>{t('privacyPolicy_dataProtectionRights.point2')}</li>
          <li>{t('privacyPolicy_dataProtectionRights.point3')}</li>
          <li>{t('privacyPolicy_dataProtectionRights.point4')}</li>
          <li>{t('privacyPolicy_dataProtectionRights.point5')}</li>
          <li>{t('privacyPolicy_dataProtectionRights.point6')}</li>
        </ul>
        <p>
          {t('privacyPolicy_dataProtectionRights.paragraph2')}{' '}
          <a href={`mailto:${t('privacyPolicy_email')}`}>
            <strong>{t('privacyPolicy_email')}</strong>
          </a>
        </p>

        <h3>{t('privacyPolicy_websiteSecurity.title')}</h3>
        <p>{t('privacyPolicy_websiteSecurity.paragraph1')}</p>

        <h2>{t('privacyPolicy_cookies.title')}</h2>
        <p>
          {t('privacyPolicy_cookies.paragraph1')}{' '}
          <a href={t('privacyPolicy_cookies.link')}>{t('privacyPolicy_cookies.linkDescription')}</a>
        </p>

        <h3>{t('privacyPolicy_howWeUseCookies.title')}</h3>
        <p>{t('privacyPolicy_howWeUseCookies.paragraph1')}</p>
        <ul className="dashed">
          <li>{t('privacyPolicy_howWeUseCookies.point1')}</li>
          <li>{t('privacyPolicy_howWeUseCookies.point2')}</li>
        </ul>

        <h3>{t('privacyPolicy_typesOfCookies.title')}</h3>
        <p>{t('privacyPolicy_typesOfCookies.paragraph1')}</p>
        <ul className="dashed">
          <li>{t('privacyPolicy_typesOfCookies.point1')}</li>
          <li>{t('privacyPolicy_typesOfCookies.point2')}</li>
          <li>{t('privacyPolicy_typesOfCookies.point3')}</li>
        </ul>

        <h3>{t('privacyPolicy_manageCookies.title')}</h3>
        <p>{t('privacyPolicy_manageCookies.paragraph1')}</p>

        <h3>{t('privacyPolicy_privacyPolicyOtherWebsite.title')}</h3>
        <p>{t('privacyPolicy_privacyPolicyOtherWebsite.paragraph1')}</p>

        <h3>{t('privacyPolicy_changesprivacyPolicy_title')}</h3>
        <p>{t('privacyPolicy_changesprivacyPolicy_paragraph1')}</p>

        <h3>{t('privacyPolicy_howToContactUs.title')}</h3>
        <p>
          {t('privacyPolicy_howToContactUs.paragraph1')}{' '}
          <a href={`mailto:${t('privacyPolicy_email')}`}>
            <strong>{t('privacyPolicy_email')}</strong>
          </a>
        </p>

        <h3>{t('privacyPolicy_howToContactAuthority.title')}</h3>
        <p>{t('privacyPolicy_howToContactAuthority.paragraph1')}</p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
