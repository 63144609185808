import i18next from 'i18next'
import React, { useState } from 'react'

import { Link, useLocation } from 'react-router-dom'

const Header = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nextLng'))

  const location = useLocation()
  const pagesWhereLanguageSelectorShouldBeHidden = ['/privacy-policy']
  const languageSelectorShouldBeHidden = pagesWhereLanguageSelectorShouldBeHidden.includes(location.pathname)

  return (
    <header>
      <div className="wrapper">
        <span className="e4e-logo" id="logo">
          <Link to="/">
            English4<span>Exec</span>
          </Link>
        </span>
        <span
          className={languageSelectorShouldBeHidden ? 'hide' : 'btn'}
          onClick={() => {
            const languageToChange = i18next.language === 'es' ? 'en' : 'es'
            i18next.changeLanguage(languageToChange)
            setSelectedLanguage(languageToChange)
          }}
        >
          <svg
            id="language-selector-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="15.5"
            height="15.5"
            viewBox="0 0 15.5 15.5"
          >
            <g id="Orion_world-globe" transform="translate(-1.607 -1.5)">
              <ellipse
                id="layer2"
                cx="3.695"
                cy="7.25"
                rx="3.695"
                ry="7.25"
                transform="translate(5.662 2)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="layer2-2"
                data-name="layer2"
                d="M4.5,12.15H17.806M4.5,6.35H17.806M11.153,2V16.5"
                transform="translate(-1.796 0)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <ellipse
                id="layer1"
                cx="7.25"
                cy="7.198"
                rx="7.25"
                ry="7.198"
                transform="translate(2.107 2.052)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </svg>
          {selectedLanguage.toUpperCase()}
        </span>
      </div>
    </header>
  )
}

export default Header
