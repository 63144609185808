import React from 'react'

export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="94" height="94.039" viewBox="0 0 94 94.039">
    <g id="Group_1352" data-name="Group 1352" transform="translate(-243 -1297.994)">
      <path
        id="layer2"
        d="M52.85,60.511c-4.95-1.5-5.85-4.35-5.85-6v-3a21.15,21.15,0,0,0,6-10.5c3.75,0,6-4.5,6-9,0-1.2,0-6-3-6,7.5-21.75-14.4-30-30-19.5-6.6,0-8.4,9.9-6,19.5-3,0-3,4.8-3,6,0,4.5,2.25,9,6,9a21.3,21.3,0,0,0,6,10.5v3c0,2.4-.75,5.4-12.9,7.8A17.5,17.5,0,0,0,2,75.511H50.45"
        transform="translate(243 1298.022)"
        fill="none"
        stroke="#0865de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <circle
        id="layer1"
        cx="21"
        cy="21"
        r="21"
        transform="translate(293 1348.033)"
        fill="none"
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <path
        id="layer1-2"
        data-name="layer1"
        d="M42,51H60m-9,9V42"
        transform="translate(263 1318.033)"
        fill="none"
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
    </g>
  </svg>
)
