import React from 'react'

export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="104" height="63.322" viewBox="0 0 104 63.322">
    <g id="Orion_eye" transform="translate(-0.5 -12.5)">
      <path
        id="layer2"
        d="M52.5,14.5c-29.153,0-50,29.661-50,29.661s20.847,29.661,50,29.661,50-29.661,50-29.661S81.653,14.5,52.5,14.5Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#262626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <circle
        id="layer1"
        cx="16.949"
        cy="16.949"
        r="16.949"
        transform="translate(35.551 26.364)"
        fill="none"
        stroke="#0865de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <path
        id="layer1-2"
        data-name="layer1"
        d="M28.872,29.5A6.78,6.78,0,0,0,34.8,39.669"
        transform="translate(17.695 10.424)"
        fill="none"
        stroke="#0865de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
    </g>
  </svg>
)
