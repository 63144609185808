import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import CurrencySelector from './CurrencySelector'

const ISACalculator = () => {
  const { t } = useTranslation()
  const exampleIncomes = 55000
  const [incomes, setIncomes] = useState()
  const [currencySymbol, setCurrencySymbol] = useState('$')
  const incomesToShow = incomes ? incomes : exampleIncomes
  const [numberOfQuarters] = useState(4)
  const isa = (incomesToShow * 0.1).toFixed(2)
  const splitPayment = ((isa / 12) * 3).toFixed(2)

  const TableHeader = (
    <thead>
      <tr>
        <th>{t('isacalculator_estimation')}</th>
        <th>{t('isacalculator_income')}</th>
        <th>{t('isacalculator_percentage')}</th>
        <th>{t('isacalculator_payment')}</th>
      </tr>
    </thead>
  )

  const yearRow = (number) => (
    <tr key={number}>
      <th>
        {t('isacalculator_trimester')} {number}
      </th>
      <td>
        {currencySymbol} {incomesToShow}
      </td>
      <td>
        {currencySymbol} {isa}
      </td>
      <td>
        {currencySymbol} {splitPayment}
      </td>
    </tr>
  )

  const Payment = (
    <div id="total-payment-wrapper">
      <span>
        {t('isacalculator_result')}
        <strong>
          {currencySymbol} {(splitPayment * numberOfQuarters).toFixed(2)}
        </strong>
      </span>
    </div>
  )

  return (
    <section id="isa-calculator">
      <div className="rounded-section bg-blue">
        <div className="wrapper">
          <h2 id="isa-calculator__title">{t('isacalculator_title')}</h2>
          <p id="isa-calculator__description">{t('isacalculator_description')}</p>
          <div id="calculator-wrapper">
            <div>
              <p id="currency-title">{t('isacalculator_input_currency')}</p>
              <p>{t('isacalculator_input_title')}</p>
            </div>
            <CurrencySelector setCurrencySymbol={setCurrencySymbol} />
            <input
              type="number"
              name=""
              id=""
              value={incomes || ''}
              placeholder={'e.g. 55000'}
              onChange={(e) => setIncomes(e.target.value)}
            />
            <table className="mobile-hide tablet-hide">
              {TableHeader}
              <tbody>{[...Array(numberOfQuarters)].map((element, x) => yearRow(x + 1))}</tbody>
            </table>
            <table className="desktop-hide">
              <thead>
                <tr>
                  <th>{t('isacalculator_estimation')}</th>
                  <th>{t('isacalculator_income')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{t('isacalculator_trimester')} 1</th>
                  <td>
                    {currencySymbol} {incomesToShow}
                  </td>
                </tr>
                <tr>
                  <th>{t('isacalculator_trimester')} 2</th>
                  <td>
                    {currencySymbol} {incomesToShow}
                  </td>
                </tr>
                <tr>
                  <th>{t('isacalculator_trimester')} 3</th>
                  <td>
                    {currencySymbol} {incomesToShow}
                  </td>
                </tr>
                <tr>
                  <th>{t('isacalculator_trimester')} 4</th>
                  <td>
                    {currencySymbol} {incomesToShow}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="desktop-hide">
              <thead>
                <tr>
                  <th>{t('isacalculator_percentage')}</th>
                  <th>{t('isacalculator_payment')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {currencySymbol} {isa}
                  </td>
                  <td>
                    {currencySymbol} {splitPayment}
                  </td>
                </tr>
                <tr>
                  <td>
                    {currencySymbol} {isa}
                  </td>
                  <td>
                    {currencySymbol} {splitPayment}
                  </td>
                </tr>
                <tr>
                  <td>
                    {currencySymbol} {isa}
                  </td>
                  <td>
                    {currencySymbol} {splitPayment}
                  </td>
                </tr>
                <tr>
                  <td>
                    {currencySymbol} {isa}
                  </td>
                  <td>
                    {currencySymbol} {splitPayment}
                  </td>
                </tr>
              </tbody>
            </table>
            {Payment}
            <span className="maximun-payment">
              {t('isacalculator_maxpayment')}
              {currencySymbol} 12.000
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ISACalculator
