import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const fallbackLng = ['en']
const availableLanguages = ['en', 'es']

const randomValue = Math.random()

i18n
  .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.
    backend: {
      /* translation file path */
      loadPath: `/assets/i18n/{{ns}}/{{lng}}.json?_=${randomValue}`
    },
    cleanCode: true,

    detection: {
      checkWhitelist: true // options for language detection
    },
    keySeparator: false,

    ns: ['translations'],
    defaultNS: 'translations',
    react: {
      wait: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false // no need for react. it escapes by default
    }
  })

export default i18n
