import React from 'react'
import MethodDescriptionCard from './MethodDescriptionCard'

import { useTranslation } from 'react-i18next'

import svg_card1 from '../assets/MethodDescriptionCard/add-user-svg.js'
import svg_card2 from '../assets/MethodDescriptionCard/target-svg.js'
import svg_card3 from '../assets/MethodDescriptionCard/eye-svg.js'

const MethodDescription = () => {
  const { t } = useTranslation()
  const card1 = {
    svg: svg_card1,
    title: t('cards_english_title'),
    description: t('cards_english_content')
  }
  const card2 = {
    svg: svg_card2,
    title: t('cards_experts_title'),
    description: t('cards_experts_content')
  }
  const card3 = {
    svg: svg_card3,
    title: t('cards_future_title'),
    description: t('cards_future_content')
  }
  return (
    <section id="method-description">
      <div className="rounded-section bg-white">
        <div className="wrapper">
          <h2 id="method-description__title">
            {t('method_title')}
            <span className="e4e-logo">
              English4<span>Exec</span>
            </span>
            ?
          </h2>
          <p id="method-description__subtitle">{t('method_description')}</p>
          <div className="method-description-cards">
            <MethodDescriptionCard {...card1} />
            <MethodDescriptionCard {...card2} />
            <MethodDescriptionCard {...card3} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MethodDescription
