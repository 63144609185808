import React from 'react'

import { useTranslation } from 'react-i18next'
import ImageWithText from './ImageWithText'
import MethodDescription from './MethodDescription'

import backgroundImage from '../assets/Banner/background.png'
import JoinEnglish4Execs from './JoinEnglish4Execs'
import ISACalculator from './ISACalculator'

const LandingContent = () => {
  const { t } = useTranslation()

  const banner = {
    title: t('banner_title'),
    description: t('banner_subtitle'),
    backgroundImage
  }
  return (
    <main>
      <ImageWithText {...banner} />
      <MethodDescription />
      <ISACalculator />
      <JoinEnglish4Execs />
    </main>
  )
}

export default LandingContent
