import React, { useEffect, useState } from 'react'
import { Col, Row, Toast } from 'react-bootstrap'
import CookieIcon from '../assets/Cookies/CookieIcon'
import CrossIcon from '../assets/Cookies/CrossIcon'
import Cookies from 'universal-cookie/cjs'
import { useTranslation } from 'react-i18next'

const cookies = new Cookies()

const CookieBanner = () => {
  const { t } = useTranslation()
  const [showToast, setShowToast] = useState(false)

  const handleCookieBanner = () => {
    setShowToast(false)
    cookies.set('cookies-accepted', 'true')
  }

  useEffect(() => {
    if (cookies.get('cookies-accepted') === 'true') {
      setShowToast(false)
    } else {
      setShowToast(true)
    }
  }, [])

  return (
    <div id={'cookie-banner'}>
      <Toast show={showToast} animation={false}>
        <Toast.Body>
          <Row>
            <Col id={'cookie-icon-column'} xs={2}>
              <CookieIcon />
            </Col>
            <Col id={'text-column'} xs={8}>
              <p>{t('cookieBanner.text')}</p>
              <p>
                <a href={'/privacy-policy'} onClick={() => handleCookieBanner()}>
                  <strong>{t('cookieBanner.link')}</strong>
                </a>
              </p>
            </Col>
            <Col id={'cross-icon-column'} xs={2} onClick={() => handleCookieBanner()}>
              <CrossIcon />
            </Col>
          </Row>
        </Toast.Body>
      </Toast>
    </div>
  )
}

export default CookieBanner
