import React from 'react'

const CookieIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31.748" height="31.6" viewBox="0 0 31.748 31.6">
    <g id="Group_1372" data-name="Group 1372" transform="translate(-552.2 -650.829)">
      <g id="Group_1369" data-name="Group 1369" transform="translate(557.655 654.215)">
        <circle
          id="Ellipse_2"
          data-name="Ellipse 2"
          cx="1.638"
          cy="1.638"
          r="1.638"
          transform="translate(17.586 -1.638)"
          fill="#fff"
        />
        <circle
          id="Ellipse_3"
          data-name="Ellipse 3"
          cx="0.948"
          cy="0.948"
          r="0.948"
          transform="translate(19.914 4.569)"
          fill="#fff"
        />
        <circle
          id="Ellipse_4"
          data-name="Ellipse 4"
          cx="0.733"
          cy="0.733"
          r="0.733"
          transform="translate(24.828 4.569)"
          fill="#fff"
        />
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="2.069"
          cy="2.069"
          r="2.069"
          transform="translate(4.569 1.638)"
          fill="#fff"
        />
        <circle
          id="Ellipse_6"
          data-name="Ellipse 6"
          cx="1.724"
          cy="1.724"
          r="1.724"
          transform="translate(0 12.845)"
          fill="#fff"
        />
        <circle
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="2.112"
          cy="2.112"
          r="2.112"
          transform="translate(14.914 14.741)"
          fill="#fff"
        />
        <circle
          id="Ellipse_8"
          data-name="Ellipse 8"
          cx="0.819"
          cy="0.819"
          r="0.819"
          transform="translate(9.31 11.207)"
          fill="#fff"
        />
        <circle
          id="Ellipse_9"
          data-name="Ellipse 9"
          cx="0.647"
          cy="0.647"
          r="0.647"
          transform="translate(7.414 19.615)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_1065"
        data-name="Path 1065"
        d="M652.061,213.051a6.642,6.642,0,0,1-8.117-8.28,4.311,4.311,0,0,1-2.679-3.99c0-.134.007-.266.019-.4a15,15,0,1,0,13.085,14.879c0-.183,0-.365-.011-.546A2.926,2.926,0,0,1,652.061,213.051Z"
        transform="translate(-71.368 451.366)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.6"
      />
    </g>
  </svg>
)

export default CookieIcon
