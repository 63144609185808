/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState } from 'react'

import { getAllISOCodes, getParamByParam } from 'iso-country-currency'
const countries = getAllISOCodes()

import FormControl from 'react-bootstrap/FormControl'
import Dropdown from 'react-bootstrap/Dropdown'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    className="custom-toggle__selected"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <span>{children}</span>
    <span>&#x25bc;</span>
  </a>
))

const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('')

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <FormControl
        autoFocus
        className="mx-3 my-2 w-auto"
        placeholder="Type to filter..."
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <ul className="list-unstyled">
        {React.Children.toArray(children).filter((child) => {
          return !value || child.props.children.join().toLowerCase().startsWith(value)
        })}
      </ul>
    </div>
  )
})

const CurrencySelector = ({ setCurrencySymbol }) => {
  const [currency, setCurrency] = useState('USD')
  const handleCurrencyClick = (e) => {
    setCurrency(e)
    setCurrencySymbol(getParamByParam('currency', e, 'symbol'))
  }
  return (
    <div id="currency-selector-wrapper">
      <Dropdown onSelect={handleCurrencyClick}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {currency}
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          {countries.map((country) => (
            <Dropdown.Item key={country.iso} eventKey={country.currency}>
              {country.countryName} ({country.currency})
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default CurrencySelector
