import React from 'react'
import PropTypes from 'prop-types'

const ImageWithText = ({ title, description, backgroundImage }) => {
  return (
    <section id="presentation" className="image-with-text">
      <div className="wrapper">
        <div className="banner__content">
          <h1>{title}</h1>
          <p>{description}</p>
          <div className="arrow-container">
            <svg
              id="Group_181"
              data-name="Group 181"
              xmlns="http://www.w3.org/2000/svg"
              width="34.574"
              height="34.574"
              viewBox="0 0 34.574 34.574"
            >
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="17.287" cy="17.287" r="17.287" fill="#0865de" />
              <path
                id="Path_4"
                data-name="Path 4"
                d="M25.168,14.2l-8.135,8.033L9,14.2"
                transform="translate(0.152 0.24)"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
        <div className="banner__image" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      </div>
    </section>
  )
}

ImageWithText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.string
}

export default ImageWithText
